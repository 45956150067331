import React from 'react';
import content from '../../content/content';


function ResumePage(){
    return (
        <div>
            <div className="text-4xl text-center font-extrabold pt-12 pb-0 mb-0 underline decoration-dotted underline-offset-8 decoration-yellow-400">
                <span className="bg-clip-text text-transparent bg-gradient-to-r from-pink-500 to-yellow-500">
                {content.resume.title}
                </span>
            </div>
            <div className="grid grid-cols-1 place-content-center place-items-center pt-3">
                <p className='no-underline py-1 text-s animate__animated animate__zoomIn '> 
                𑁍 View my one page Curriculum Vitae in PDF <a className='hover:text-red-500 underline' href={content.resume.pdf_link} target="_blank" rel="noreferrer">here</a> 𑁍
                </p>
                <div className='flex flex-wrap justify-center px-3'>
                    <svg className='w-3' viewBox="0 0 32 32"><path d="M27.308,20.649l-2.2-2.2a3.521,3.521,0,0,0-4.938-.021,2.152,2.152,0,0,1-2.729.267A15.026,15.026,0,0,1,13.3,14.562a2.181,2.181,0,0,1,.284-2.739A3.521,3.521,0,0,0,13.553,6.9l-2.2-2.2a3.514,3.514,0,0,0-4.961,0l-.633.634c-3.3,3.3-3.053,10.238,3.813,17.1,4.14,4.141,8.307,5.875,11.686,5.875a7.5,7.5,0,0,0,5.418-2.061l.634-.634A3.513,3.513,0,0,0,27.308,20.649ZM25.894,24.2l-.634.634c-2.6,2.6-8.339,2.125-14.276-3.813S4.571,9.34,7.171,6.74L7.8,6.107a1.511,1.511,0,0,1,2.133,0l2.2,2.2a1.511,1.511,0,0,1,.021,2.11,4.181,4.181,0,0,0-.531,5.239,17.01,17.01,0,0,0,4.713,4.706,4.179,4.179,0,0,0,5.231-.517,1.512,1.512,0,0,1,2.118.013l2.2,2.2A1.51,1.51,0,0,1,25.894,24.2Z"/></svg> 
                    <p className='no-underline py-1 text-xs animate__animated animate__zoomIn '> 
                    &nbsp; (+65) 85864350 | &nbsp;
                    </p>

                    <svg className='w-3' viewBox="0 0 32 32"><path fill="#231f20" d="M16,14.81,28.78,6.6A3,3,0,0,0,27,6H5a3,3,0,0,0-1.78.6Z"/><path fill="#231f20" d="M16.54,16.84h0l-.17.08-.08,0A1,1,0,0,1,16,17h0a1,1,0,0,1-.25,0l-.08,0-.17-.08h0L2.1,8.26A3,3,0,0,0,2,9V23a3,3,0,0,0,3,3H27a3,3,0,0,0,3-3V9a3,3,0,0,0-.1-.74Z"/></svg>
                    <p className='no-underline py-1 text-xs animate__animated animate__zoomIn '> 
                    &nbsp; <a href={content.home.gmail_url} target="_blank" rel="noreferrer"> ferlitahalim@gmail.com </a> | &nbsp;
                    </p>

                    <svg className='w-3' viewBox="0 0 16 16"><path d="M7.999 0C3.582 0 0 3.596 0 8.032a8.031 8.031 0 0 0 5.472 7.621c.4.074.546-.174.546-.387 0-.191-.007-.696-.011-1.366-2.225.485-2.695-1.077-2.695-1.077-.363-.928-.888-1.175-.888-1.175-.727-.498.054-.488.054-.488.803.057 1.225.828 1.225.828.714 1.227 1.873.873 2.329.667.072-.519.279-.873.508-1.074-1.776-.203-3.644-.892-3.644-3.969 0-.877.312-1.594.824-2.156-.083-.203-.357-1.02.078-2.125 0 0 .672-.216 2.2.823a7.633 7.633 0 0 1 2.003-.27 7.65 7.65 0 0 1 2.003.271c1.527-1.039 2.198-.823 2.198-.823.436 1.106.162 1.922.08 2.125.513.562.822 1.279.822 2.156 0 3.085-1.87 3.764-3.652 3.963.287.248.543.738.543 1.487 0 1.074-.01 1.94-.01 2.203 0 .215.144.465.55.386A8.032 8.032 0 0 0 16 8.032C16 3.596 12.418 0 7.999 0z"/></svg>
                    <p className='no-underline py-1 text-xs animate__animated animate__zoomIn '> 
                    &nbsp; <a href={content.home.github_url} target="_blank" rel="noreferrer"> fer-bot </a> | &nbsp;
                    </p>

                    <svg className='w-3' viewBox="0 0 16 16"><path d="M0 5h3.578v11H0zM13.324 5.129c-.038-.012-.074-.025-.114-.036a2.32 2.32 0 0 0-.145-.028A3.207 3.207 0 0 0 12.423 5c-2.086 0-3.409 1.517-3.845 2.103V5H5v11h3.578v-6s2.704-3.766 3.845-1v7H16V8.577a3.568 3.568 0 0 0-2.676-3.448z"/><circle cx="1.75" cy="1.75" r="1.75"/></svg> 
                    <p className='no-underline py-1 text-xs animate__animated animate__zoomIn '> 
                    &nbsp; <a href={content.home.likedin_url} target="_blank" rel="noreferrer"> ferlita-halim </a>&nbsp;
                    </p>
                </div>
            </div>

            <div className=''>

            {content.resume.content.map((section) => (
            <div key={section.section_title} className="lg:mx-64 md:mx-44 sm:mx-20 mx-5">
                <div className="text-4xl text-left mt-12 pb-6 mb-0">
                    <span className="bg-clip-text text-transparent bg-gradient-to-t from-pink-500 to-yellow-500">
                    {section.section_title}
                    </span>
                </div>

                <div className="border-l-4  pb-0 mb-0 bg-clip-border border-transparent bg-gradient-to-b from-pink-500 to-yellow-500">
                <ol className="bg-clip-padding bg-white justify-items-start text-left">
                {section.section_content.map((section_point, index) => (
                    <li key={index}>
                        <div className="flex flex-start items-center">
                        <div className="bg-pink-600 h-3 w-3 flex items-center justify-center rounded-full -ml-2 mr-3 -mt-4"></div>
                            <p className="text-xl text-gray-900 text-wrap">{section_point.title}</p>
                        </div>

                        <div className="ml-5 mb-5 pb-2">
                            <p className="text-l text-gray-600">{section_point.position}</p>
                            <p className="text-sm text-gray-600 mb-1">{section_point.time_detail}</p>
                            {section_point.links.map((link) => (
                                <a key={link.url} href={link.url} target="_blank" rel="noreferrer" className="inline-block px-2 py-1 mr-2 mt-1 bg-pink-500 text-white font-medium text-xs leading-tight rounded-full shadow-md hover:bg-yellow-500 hover:shadow-lg hover:text-gray-600">{link.title} ➤</a>
                            ))}

                            <ul className="list-disc ml-4 mb-4 mt-4">
                            {section_point.job_detail.map((line, index)=>(
                                <li key={index} className="text-gray-700 text-xs mb-1 text-justify">{line} </li>
                            ))}
                            </ul>
                        </div>
                    </li>
                ))}
                </ol>
                </div>
            </div>
            ))}

            </div>

            <div className="grid grid-cols-1 place-content-center place-items-center py-10">
                <p className='no-underline hover:text-red-500 py-1 px-3 text-xs '> 
                Copyright © 2022 Ferlita Halim. Created with ♡.
                </p> 
            </div>

            
        </div>
    );
}

export default ResumePage;
