import React from 'react';
import 'animate.css';
import content from '../../content/content';

function MainPage(){
  return (
    <div>
      <div className='bg-gradient-to-br from-pink-700 to-yellow-300'>
        <p className="text-xl text-center pt-9 text-gray-200 animate__animated animate__pulse">Nice to meet you, I'm</p>
        <p className="text-5xl text-center animate__animated animate__rubberBand animate__slow font-bold tracking-wide text-gray-800 pt-1 ">{content.home.name}</p>
        <div className="flex justify-center mb-6">
          <img src={content.home.photo_name} alt="profile pic" className="w-32 animate__animated animate__fadeIn animate__slow rounded-full" />
        </div>
        <div className='lg:px-40 md:px-32'>
        {content.home.description.map((line, index)=>( index%2 ? 
          <p key={index} className="text-l text-center pt-0 text-gray-100 px-5 animate__animated animate__backInLeft animate__delay-1s">{line}</p> : 
          <p key={index} className="text-l text-center pt-0 text-gray-100 px-5 animate__animated animate__backInRight animate__delay-1s">{line}</p>
        ))}
        </div>
        <div className="pb-9"/>

        <div className="flex text-center justify-center pb-12 animate__animated animate__fadeInUp animate__delay-2s">
          <a href={content.home.likedin_url} className="px-1">
            <svg viewBox="0 0 448 512" className="w-5 h-5"><path fill="white" d="M100.28 448H7.4V148.9h92.88zM53.79 108.1C24.09 108.1 0 83.5 0 53.8a53.79 53.79 0 0 1 107.58 0c0 29.7-24.1 54.3-53.79 54.3zM447.9 448h-92.68V302.4c0-34.7-.7-79.2-48.29-79.2-48.29 0-55.69 37.7-55.69 76.7V448h-92.78V148.9h89.08v40.8h1.3c12.4-23.5 42.69-48.3 87.88-48.3 94 0 111.28 61.9 111.28 142.3V448z"/></svg>
          </a>
          <a href={content.home.github_url} className="px-1">
            <svg viewBox="0 0 496 512" className="w-5 h-5"><path fill="white" d="M165.9 397.4c0 2-2.3 3.6-5.2 3.6-3.3.3-5.6-1.3-5.6-3.6 0-2 2.3-3.6 5.2-3.6 3-.3 5.6 1.3 5.6 3.6zm-31.1-4.5c-.7 2 1.3 4.3 4.3 4.9 2.6 1 5.6 0 6.2-2s-1.3-4.3-4.3-5.2c-2.6-.7-5.5.3-6.2 2.3zm44.2-1.7c-2.9.7-4.9 2.6-4.6 4.9.3 2 2.9 3.3 5.9 2.6 2.9-.7 4.9-2.6 4.6-4.6-.3-1.9-3-3.2-5.9-2.9zM244.8 8C106.1 8 0 113.3 0 252c0 110.9 69.8 205.8 169.5 239.2 12.8 2.3 17.3-5.6 17.3-12.1 0-6.2-.3-40.4-.3-61.4 0 0-70 15-84.7-29.8 0 0-11.4-29.1-27.8-36.6 0 0-22.9-15.7 1.6-15.4 0 0 24.9 2 38.6 25.8 21.9 38.6 58.6 27.5 72.9 20.9 2.3-16 8.8-27.1 16-33.7-55.9-6.2-112.3-14.3-112.3-110.5 0-27.5 7.6-41.3 23.6-58.9-2.6-6.5-11.1-33.3 2.6-67.9 20.9-6.5 69 27 69 27 20-5.6 41.5-8.5 62.8-8.5s42.8 2.9 62.8 8.5c0 0 48.1-33.6 69-27 13.7 34.7 5.2 61.4 2.6 67.9 16 17.7 25.8 31.5 25.8 58.9 0 96.5-58.9 104.2-114.8 110.5 9.2 7.9 17 22.9 17 46.4 0 33.7-.3 75.4-.3 83.6 0 6.5 4.6 14.4 17.3 12.1C428.2 457.8 496 362.9 496 252 496 113.3 383.5 8 244.8 8zM97.2 352.9c-1.3 1-1 3.3.7 5.2 1.6 1.6 3.9 2.3 5.2 1 1.3-1 1-3.3-.7-5.2-1.6-1.6-3.9-2.3-5.2-1zm-10.8-8.1c-.7 1.3.3 2.9 2.3 3.9 1.6 1 3.6.7 4.3-.7.7-1.3-.3-2.9-2.3-3.9-2-.6-3.6-.3-4.3.7zm32.4 35.6c-1.6 1.3-1 4.3 1.3 6.2 2.3 2.3 5.2 2.6 6.5 1 1.3-1.3.7-4.3-1.3-6.2-2.2-2.3-5.2-2.6-6.5-1zm-11.4-14.7c-1.6 1-1.6 3.6 0 5.9 1.6 2.3 4.3 3.3 5.6 2.3 1.6-1.3 1.6-3.9 0-6.2-1.4-2.3-4-3.3-5.6-2z"/></svg>
          </a>
          <a href={content.home.gmail_url} className="px-1">
          <svg viewBox="0 0 488 512" className="w-5 h-5"><path fill="white" d="M488 261.8C488 403.3 391.1 504 248 504 110.8 504 0 393.2 0 256S110.8 8 248 8c66.8 0 123 24.5 166.3 64.9l-67.5 64.9C258.5 52.6 94.3 116.6 94.3 256c0 86.5 69.1 156.6 153.7 156.6 98.2 0 135-70.4 140.8-106.9H248v-85.3h236.1c2.3 12.7 3.9 24.9 3.9 41.4z"/></svg>
          </a>
        </div>
      </div>


      <div className="text-3xl text-center font-extrabold pt-12 pb-0 mb-0 animate__animated animate__flipInY animate__delay-2s">
        <span className="bg-clip-text text-transparent bg-gradient-to-r from-pink-500 to-yellow-500">
          About Me
        </span>
      </div>


      <div className="container pt-0 lg:px-40 md:px:32 mx-auto">
        <div className="flex flex-wrap items-center">
          <div className="block w-full md:flex grow-0 shrink-0 basis-auto md:w-6/12 lg:w-6/12 animate__animated animate__zoomInLeft animate__delay-2s">
            <img
              src={content.home.aboutme_illustration}
              alt="Computer Illustration"
              className="w-full rounded-t-lg lg:rounded-tr-none lg:rounded-bl-lg"
            />
          </div>

          <div className="grow-0 shrink-0 basis-auto w-full md:w-6/12 lg:w-6/12 animate__animated animate__zoomInRight animate__delay-2s">
            <div className="px-6 pt-5 md:px-12">
              <p className="text-xl text-gray-900">{content.home.aboutme_line1}</p>
              <p className="text-l text-gray-600">{content.home.aboutme_line2}</p>
              <p className="text-sm text-gray-600 mb-4">{content.home.aboutme_line3}</p>
              {content.home.aboutme_paragrhaphs.map((line, index)=>(
                <p key={index} className="text-sm text-gray-900 text-justify">{line} <br/><br/></p>
              ))}
            </div>
          </div>
        </div>
      </div>

      <div className="container pt-2 lg:px-40 md:px:32 mx-auto items-center justify-center animate__animated animate__zoomIn animate__delay-2s">
        <p className="text-xl text-gray-900 text-center pb-2">My current interests: </p>
        <div className="flex flex-row flex-wrap items-center justify-center">
          {content.home.aboutme_interests.map((interest) => (
            <span key={interest} className="px-3 py-1 mx-1 my-1 rounded-full border border-gray-300 text-white font-semibold text-sm flex align-center w-max active:bg-gray-300 transition duration-300 ease bg-gradient-to-r from-pink-500 to-yellow-500">
              {interest}
            </span>
          ))}
        </div>
      </div>


      <div className="text-3xl text-center font-extrabold pt-12 pb-3 mb-0 animate__animated animate__flipInY animate__delay-2s">
        <span className="bg-clip-text text-transparent bg-gradient-to-r from-pink-500 to-yellow-500">
          My Skills
        </span>
      </div>

      {Object.entries(content.home.skills).map(([title, skills]) => (
        <div key={title} className="container pt-2 pb-4 lg:px-40 md:px:32  px-5 mx-auto items-center justify-center animate__animated animate__zoomIn animate__delay-2s">
        <p className="text-l text-gray-900 pb-2">{title}: </p>
        <div className="flex flex-row flex-wrap items-center">
          {skills.map((interest) => (
            <span key={interest} className="px-3 py-1 mx-1 my-1 rounded-full border border-gray-300 text-gray-600 font-semibold text-sm flex align-center w-max active:bg-gray-300 transition duration-300 ease">
              {interest}
            </span>
          ))}
        </div>
        </div>
      ))}

      <div className="text-3xl text-center font-extrabold pt-12 pb-5 mb-0 animate__animated animate__flipInY animate__delay-2s">
        <span className="bg-clip-text text-transparent bg-gradient-to-r from-pink-500 to-yellow-500">
          More Informations
        </span>
      </div>
      
      <p className="text-l text-center py-3 text-gray-900 px-5 animate__animated animate__flipInY animate__delay-2s">
        {content.home.more_content}
      </p> 

      <div className="flex flex-row flex-wrap mb-15 text-gray-700 mx-12 text-center justify-center">
        
        {content.home.more_link.map((link, index) => (
          <div key={index} className='w-72 px-5 py-3 animate__animated animate__flipInX animate__delay-2s'>
            <div className="block rounded-lg shadow-lg bg-white">
              <div className="overflow-hidden rounded-t-lg h-20 bg-gradient-to-b from-pink-500 to-yellow-500" ></div>
              <div className="flex w-20 h-20 -mt-12 overflow-hidden shadow-md border border-2 border-gray-100 rounded-full mx-auto bg-white -mb-6 items-center">
                <img src={link.icon} alt="Link Icon"/>
              </div>
              <div className="p-6 pt-8">
                <h4 className="text-xl font-semibold mb-2 text-gray-600"> {link.title} </h4>
                <hr />
                <a href={link.link} className="inline-block px-6 py-2 mt-5 bg-pink-500 text-white text-xs leading-tight uppercase rounded-full shadow-md hover:bg-yellow-500 hover:shadow-lg hover:text-gray-600">
                  check out ➤
                </a>
              </div>
            </div>
          </div>
        ))}
        
      </div>

      <div className="grid grid-cols-1 place-content-center place-items-center py-10">
        <p className='no-underline hover:text-red-500 py-1 px-3 text-xs animate__animated animate__zoomIn animate__delay-2s'> 
          Copyright © 2022 Ferlita Halim. Created with ♡.
        </p> 
      </div>
    </div>
  );
}

export default MainPage;
