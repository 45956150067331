import NotFoundPage from "./pages/NotFoundPage";
import MainPage from "./pages/MainPage";
import ResumePage from "./pages/ResumePage";
import PortfolioPage from "./pages/PortfolioPage";

const routes = [
  {
    id: 1,
    path: '*',
    exact: true,
    header: false,
    name: '',
    component: NotFoundPage,
  },
  {
    id: 2,
    path: '/',
    exact: true,
    header: true,
    name: 'Home',
    component: MainPage,
  },
  {
    id: 3,
    path: '/resume',
    exact: true,
    header: true,
    name: 'Resume',
    component: ResumePage,
  },
  {
    id: 4,
    path: '/portfolio',
    exact: true,
    header: true,
    name: 'Portfolio',
    component: PortfolioPage,
  },
];

export default routes;
