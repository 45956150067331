import React, { useState } from 'react';

function Carousel(pictures){
  const [curr, setCurr] = useState(0)
  const pic_length = pictures.length


  return (
      <div>
        <div id="default-carousel" className="relative" data-carousel="static">

            <div className="relative h-32 overflow-hidden rounded-t-lg">      
                {pictures.map((pic, idx) => (
                  idx === curr ? 
                  <div key={idx}>
                      <span className="absolute text-xl font-semibold text-white -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2 sm:text-3xl dark:text-gray-800">First Slide</span>
                      <img src={pic} className="absolute block w-full -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2" alt="..."/>
                  </div> : 
                  <div key={idx} className="hidden">
                      <span className="absolute text-xl font-semibold text-white -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2 sm:text-3xl dark:text-gray-800">First Slide</span>
                      <img src="/docs/images/carousel/carousel-1.svg" className="absolute block w-full -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2" alt="..."/>
                  </div>
                ))}
            </div>
            { pic_length > 1 && 
            <div className="absolute z-50 flex -translate-x-1/2 bottom-5 left-1/2">
              {pictures.map((_, idx) => ( idx === curr ?
                <button key={idx} type="button" className="w-4 h-1 mx-1 rounded-full bg-pink-500 shadow-lg"></button> : 
                <button key={idx} onClick={()=>setCurr(idx)} type="button" className="w-4 h-1 mx-1 rounded-full bg-yellow-400 shadow-lg"></button>
              ))}
            </div>
            }

            { pic_length > 1 && 
            <button type="button" onClick={() => setCurr((pic_length + curr - 1) % pic_length)} className="absolute top-0 left-0 z-30 flex items-center justify-center h-full px-4 cursor-pointer group focus:outline-none" data-carousel-prev>
                <span className="inline-flex items-center justify-center w-8 h-8 rounded-full sm:w-10 sm:h-10 bg-gray-200 group-hover:bg-gray-500 group-focus:ring-1 group-focus:ring-white group-focus:outline-none">
                    <svg className="w-5 h-5 text-white sm:w-6 sm:h-6 dark:text-gray-800" fill="none" stroke="white" viewBox="0 0 24 24"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M15 19l-7-7 7-7"></path></svg>
                </span>
            </button>
            }

            { pic_length > 1 && 
            <button type="button" onClick={() => setCurr((curr + 1) % pic_length)} className="absolute top-0 right-0 z-30 flex items-center justify-center h-full px-4 cursor-pointer group focus:outline-none" data-carousel-next>
                <span className="inline-flex items-center justify-center w-8 h-8 rounded-full sm:w-10 sm:h-10 bg-gray-200 group-hover:bg-gray-500 group-focus:ring-1 group-focus:ring-white group-focus:outline-none">
                    <svg className="w-5 h-5 text-white sm:w-6 sm:h-6" fill="none" stroke="white" viewBox="0 0 24 24"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 5l7 7-7 7"></path></svg>
                </span>
            </button>
            }

        </div>
      </div>
  )
}

export default Carousel