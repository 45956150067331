import { BrowserRouter, Routes, Route } from 'react-router-dom';

import routes from './routes';
import Header from './components/Header'

function App() {
  return (
    <div>
      <Header/>
    <BrowserRouter>
      <Routes>
        {routes.map((route) => (
          <Route
            key={route.id}
            path={route.path}
            exact={route.exact}
            element={<route.component />}
          />
        ))}
      </Routes>
    </BrowserRouter>
    </div>
  );
}

export default App;
