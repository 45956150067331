import React from 'react';
import routes from '../../routes';

function Header(){
    var curr_url = window.location.pathname

    return (
        <nav className="navbar navbar-expand-lg shadow-md py-2 bg-gray-900 text-gray-100 relative flex items-center w-full justify-between">
          <div className="px-6 w-full flex flex-wrap items-center justify-between">
            <div className="navbar-collapse collapse grow items-center" id="navbarSupportedContentY">
              <ul className="navbar-nav mr-auto sm:flex sm:flex-row">
                <li className="nav-item">
                    <a className="nav-link block pr-2 lg:px-2 py-2 text-yellow-300 mr-5" href='/' data-mdb-ripple="true" data-mdb-ripple-color="light">♡ Ferlita ♡</a>
                </li>
                {routes.map((route) => (route.header ? 
                    <li className="nav-item" key={route.id}>
                        {curr_url === route.path ? <a className="nav-link block pr-2 lg:px-2 py-2 text-red-400 underline underline-offset-8" href={route.path} data-mdb-ripple="true" data-mdb-ripple-color="light">{route.name}</a>
                        : <a className="nav-link block pr-2 lg:px-2 py-2 text-white hover:text-gray-700 focus:text-gray-700 transition duration-150 ease-in-out" href={route.path} data-mdb-ripple="true" data-mdb-ripple-color="light">{route.name}</a>}
                    </li>
                :<div key={route.id}></div>
                ))}
              </ul>
            </div>
          </div>
        </nav>
    )
}

export default Header