import React from 'react';
import content from '../../content/content';

function NotFoundPage(){
  return (
    <div>
      <div class="flex flex-wrap justify-center">
        <img
              src={content.notfound.imgae}
              class="max-w-sm h-auto my-10"
              alt="404 not found img"
            />
      </div>

      <div className="text-5xl text-center font-extrabold pb-0 mb-0">
          <span className="bg-clip-text text-transparent bg-gradient-to-r from-pink-500 to-yellow-500">
          404 - Page Not Found {' :('}
          </span>
      </div>

      <div className="text-center py-5 px-3 text-sm">
          Go back to&nbsp;
          <a href='/' type="button" className='underline hover:text-red-500 '> 
             homepage
          </a> 
        </div>
    </div>
  );
}

export default NotFoundPage;
