const content = {
    home: {
        name: 'Ferlita Halim',
        description: [
            "I like to explore and learn new things.", 
            "My interest lies in creating stuff by building software application especially web application.",
            "Welcome to my website, I hope you enjoy!"
        ],
        photo_name: require('./files/profile_pic.png'),
        likedin_url: 'https://www.linkedin.com/in/ferlita-halim/',
        github_url: 'https://github.com/fer-bot',
        gmail_url: 'mailto:ferlitahalim@gmail.com',
        aboutme_illustration: require('./files/aboutme_illustration.jpg'),
        aboutme_line1: "Computer Science",
        aboutme_line2: "Nanyang Technological University",
        aboutme_line3: "Class of 2023",
        aboutme_paragrhaphs: [
            "I started my journey with frontend development and picked up K8s and Docker during my first internship in my first year. I continued to dive into backend development during my free time so that I could build a whole working web application.",
            "Aside from study, I enjoy doing sports with my friends or learn a new sport. My favorite sport is basketball and table tennis.",
        ],
        aboutme_interests: [
            "web development", 
            "backend", 
            "fullstack", 
            "frontend",
            "app development",
        ],
        skills: {
            "Programming Languages": [
                'Python ★★★★★', 
                'Javascript ★★★★★' ,
                'Typescript ★★★★☆',
                'Java ★★★★☆', 
                'C ★★★☆☆', 
                'C++ ★★★☆☆', 
                'Go ★★★☆☆', 
                'SQL ★★★★★',
            ],
            "Web Development": [
                'React.js', 
                'Redux', 
                'Node.js', 
                'Express.js', 
                'HTML', 
                'CSS', 
                'jQuery', 
                'Django', 
                'Flask', 
                'Socket.io', 
                'Spring Boot',
            ],
            "DevOps / Infra / Cloud": [
                'Docker', 
                'Kubernetes', 
                'Azure CLI',
            ],
            "Other Skills": [
                'MongoDB', 
                'Firebase', 
                'PostgreSQL', 
                'MySQL', 
                'JMeter', 
                'Git', 
                'Postman', 
                'Godot',
            ]
        },
        more_content: 'Checkout my resume and portfolio!',
        more_link: [
            {
                title: "Resume",
                link: "/resume",
                icon: require('./files/link_icon_1.jpg'),
            },
            {
                title: "Portfolio",
                link: '/portfolio',
                icon: require('./files/link_icon_2.jpg'),
            },
        ]
    },
    resume: {
        title: 'Resume of Ferlita Halim',
        pdf_link: require('./files/Resume__FerlitaHalim.pdf'),
        content:[
            {
                section_title: 'Education',
                section_content: [
                    {
                        title: 'Nanyang Technological University',
                        position: 'Bachelor of Computing (Computer Science)',
                        time_detail: 'Singapore, Aug 2019 - Jun 2023 (Expected)',
                        links: [],
                        job_detail: [
                            'Got an ASEAN Scholarship which covers all tuition fees and living allowance for four years of study.'
                        ]
                    }
                ]
            },
            {
                section_title: 'Experience',
                section_content: [
                    {
                        title: 'Autodesk, Inc.',
                        position: 'Software Developer Intern - Forge Content and Schema Team',
                        time_detail: 'Singapore, Jan 2022 - Jun 2022',
                        links: [],
                        job_detail: [
                            'Involved in all the phases of software development life cycle and add new API and features to existing Forge Cloud Service using cutting‐edge Reactive programming paradigm with Functional-programming style using Spring Boot, Web Flux, Reactor and MySQL database.',
                            'Add new testcases for API testing, add code coverage testing using Java JaCoCo and improve code coverage to 85% by adding more unit test.'
                        ]
                    },
                    {
                        title: 'The Software Practice Pte Ltd',
                        position: 'Software Developer Engineer Intern',
                        time_detail: 'Singapore, May 2021 - Aug 2021',
                        links: [],
                        job_detail: [
                            'Reviewed Azure infrastructure plan and created Azure CLI script to automate the deployment.',
                            'Wrote Kubernetes deployment YAML file to deploy the project to the created Azure infrastructure.',
                            'Wrote JMeter file for load and performance testing for critical web application.'
                        ]
                    },
                    {
                        title: 'Nubela Pte Ltd',
                        position: 'Software Developer Intern',
                        time_detail: 'Singapore, Jun 2020 - Aug 2020, Dec 2020- Jan 2021',
                        links: [],
                        job_detail: [
                            'Created Google AdWords scripts for automation of Ads creation with different keywords and targeting location combinations, while also adding a tracking method to the website to analyse the conversion rate of each combination.',
                            "Implemented a landing page using React.js, Flask, and PostgresSQL and deployed the company's websites to Kubernetes.",
                            "Write a Python scheduler to automate the retrieval of SEO ranking metrics and stored to a PostgreSQL database hosted in DigitalOcean."
                        ]
                    }
                ]
            },
            {
                section_title: 'Projects',
                section_content: [
                    {
                        title: 'Personal Project- Sindo App',
                        position: 'Developer',
                        time_detail: 'Singapore, Jun 2022 - Present',
                        links: [
                            {
                                title: 'Github',
                                url: 'https://github.com/fer-bot/sindo_app'
                            },
                        ],
                        job_detail: [
                            'Design and build a web app to streamline importing goods process from China to Indonesia with multiple user types with multiple permissions for relative’s company using Django, JQuery, and MySQL.'
                        ]
                    },
                    {
                        title: 'Personal Project - Squabble',
                        position: 'Developer',
                        time_detail: 'Singapore, May 2022 - Present',
                        links: [
                            {
                                title: 'Github',
                                url: 'https://github.com/fer-bot/squabble-be'
                            },
                        ],
                        job_detail: [
                            'Design and build Squabble-alike (Wordle party game) Backend with socket programming using Go, PostgreSQL, and RedisCache.'
                        ]
                    },
                    {
                        title: 'Facial‐Recognition‐Based Office Attendance Taking System (OATS)',
                        position: 'Lead Developer',
                        time_detail: 'Singapore, Aug 2021 - Oct 2021',
                        links: [
                            {
                                title: 'Github',
                                url: 'https://github.com/l-sense'
                            },
                        ],
                        job_detail: [
                            'Led a team of 4 in developing a frontend web applications for taking attendance and a web application for admin using ReactJS and a backend using Django and deepface python library for face recognition.'
                        ]
                    },
                    {
                        title: 'DodgeWare - Educational Game',
                        position: 'Lead Game Developer',
                        time_detail: 'Singapore, Jan 2021 - Apr 2021',
                        links: [
                            {
                                title: 'Github',
                                url: 'https://github.com/9cents/game-client'
                            },
                        ],
                        job_detail: [
                            'Led a team of 3 in developing a web application for teacher dashboard to create a level, input MCQ questions, and see students\' progress and statistics, implemented using ReactJS.',
                            'Developed a 2D PC game for student to play dodging game where they should dodge the wrong MCQ answers, implemented using Godot.',
                            'Connect the teacher dashboard and the PC game with backend, implemented using NodeJS and ExpressJS.'
                        ]
                    },
                ]
            },
            {
                section_title: 'Skills',
                section_content: [
                    {
                        title: 'Languages',
                        position: '',
                        time_detail: '',
                        links: [],
                        job_detail: ['Python, Javascript / Typescript, Java, C/C++, Go, SQL']
                    },
                    {
                        title: 'Web Development',
                        position: '',
                        time_detail: '',
                        links: [],
                        job_detail: ['React.js, Redux, Node.js, Express.js, HTML, CSS, SCSS, jQuery, Django, Flask, Socket.io, Spring Boot']
                    },
                    {
                        title: 'DevOps / Infra / Cloud',
                        position: '',
                        time_detail: '',
                        links: [],
                        job_detail: ['Docker, Kubernetes, Azure CLI']
                    },
                    {
                        title: 'Other',
                        position: '',
                        time_detail: '',
                        links: [],
                        job_detail: ['MongoDB, Firebase, PostgreSQL, MySQL, JMeter, Git, Postman, Godot']
                    }
                ]
            }
        ]
    },
    portfolio: {
        title: "Portfolio of Ferlita Halim",
        content: [
            {
                title: 'Helios - Anonymous Voting WebApp',
                pics: [
                    require('./files/helios/img1.png'), 
                    require('./files/helios/img2.png'),
                    require('./files/helios/img3.png'),
                ],
                desc: 'An individual project created for Cryptography module in NTU. \n\nUsing el-gammal encryption scheme and Chaum pedersen & DCP zero knowledge algorithm.',
                stack: ['Python', 'Django', 'MongoDB', 'JS', 'HTML', 'CSS'],
                links: [
                    {
                        title: 'webapp',
                        url: 'https://helios.ferlita.dev/',
                    },
                    {
                        title: 'github',
                        url: 'https://github.com/fer-bot/helios-voting',
                    },
                ]
            },
        ]
    },
    notfound: {
        imgae: require('./files/404_img.jpg')
    }
}

export default content